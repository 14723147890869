@import url("https://fonts.googleapis.com/css2?family=Annie+Use+Your+Telescope&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&family=Righteous&display=swap");
body, html {
  background-color: #f2f2f2;
  overflow: calc(100vh - 120px);
  font-family: "Annie Use Your Telescope", cursive;
  overflow: hidden;
}

main {
  margin-top: 120px;
  overflow: auto;
  height: calc(100vh - 120px);
  background-color: #f2f2f2;
  z-index: 10;
}
@media (min-width: 768px) {
  main {
    margin-top: 80px;
  }
}
main::-webkit-scrollbar {
  width: 20px;
}
main::-webkit-scrollbar-track {
  background-color: white;
  box-shadow: inset 0 0 1em rgba(12, 74, 110, 0.4117647059);
  border-radius: 50px;
  height: 10px;
}
main::-webkit-scrollbar-thumb {
  border-radius: 100px;
  border: 5px solid transparent;
  background-clip: content-box;
  background-color: white;
  height: 50px;
}
main:hover::-webkit-scrollbar-track {
  background-color: white;
  box-shadow: inset 0 0 1em rgba(12, 74, 110, 0.9333333333);
  border-radius: 100px;
  margin-right: 10px;
  -webkit-transition: all 8s ease-in;
  transition: all 8s ease-in;
}

main::-webkit-scrollbar-thumb {
  border-radius: 100px;
  border: 5px solid transparent;
  background-clip: content-box;
  background-color: #0ea4e9;
  height: 50px;
}

section {
  height: calc(100vh - 120px);
  font-family: "Comfortaa", cursive;
}

header {
  top: 0;
  display: block;
  z-index: 1000;
}

nav.nav {
  border-radius: 100%;
}
nav.nav::before {
  display: flex;
  height: 50px;
  width: 50px;
  content: "";
  background-color: rgba(255, 255, 255, 0.5019607843);
  -webkit-animation: animate 3s infinite;
          animation: animate 3s infinite;
}

footer {
  height: 40px;
  width: 100%;
  position: fixed;
  z-index: 500;
  bottom: 0;
}

article .h3Cv {
  color: #0ea5e9;
  font-family: "Annie Use Your Telescope", cursive;
  font-size: 40px;
  transition: all 0.75s ease-in-out;
}

article::-webkit-scrollbar {
  width: 20px;
}

article::-webkit-scrollbar-track {
  background-color: white;
  box-shadow: inset 0 0 1em #0c4a6e;
  border-radius: 50px;
  height: 10px;
}

article:hover::-webkit-scrollbar-track {
  background-color: white;
  box-shadow: inset 0 0 1em #0ea5e9;
  border-radius: 100px;
  margin-right: 10px;
  -webkit-transition: all 8s ease-in;
  transition: all 8s ease-in;
}

article::-webkit-scrollbar-thumb {
  border-radius: 100px;
  border: 5px solid transparent;
  background-clip: content-box;
  background-color: white;
  height: 50px;
}

article.computerSkills label {
  width: 100%;
  font-size: 20px;
  font-weight: 400;
  font-family: "Righteous", cursive;
  color: #0c4a6e;
}

label {
  margin-bottom: 10px;
  background-color: transparent;
  display: block;
  opacity: 0;
}
label:nth-child(1) {
  -webkit-animation: fade-in 0.5s 0.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 0.5s 0.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
label:nth-child(2) {
  -webkit-animation: fade-in 0.5s 0.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 0.5s 0.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
label:nth-child(3) {
  -webkit-animation: fade-in 0.5s 0.3s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 0.5s 0.3s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
label:nth-child(4) {
  -webkit-animation: fade-in 0.5s 0.4s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 0.5s 0.4s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
label:nth-child(5) {
  -webkit-animation: fade-in 0.5s 0.5s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 0.5s 0.5s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
label:nth-child(6) {
  -webkit-animation: fade-in 0.5s 0.6s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 0.5s 0.6s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
label:nth-child(7) {
  -webkit-animation: fade-in 0.5s 0.7s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 0.5s 0.7s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
label:nth-child(8) {
  -webkit-animation: fade-in 0.5s 0.8s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 0.5s 0.8s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
label:nth-child(9) {
  -webkit-animation: fade-in 0.5s 0.9s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 0.5s 0.9s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
label:nth-child(10) {
  -webkit-animation: fade-in 0.5s 1s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 0.5s 1s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
label:nth-child(11) {
  -webkit-animation: fade-in 0.5s 1.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 0.5s 1.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
label:nth-child(12) {
  -webkit-animation: fade-in 0.5s 1.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 0.5s 1.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
label:nth-child(13) {
  -webkit-animation: fade-in 0.5s 1.3s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 0.5s 1.3s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
label:nth-child(14) {
  -webkit-animation: fade-in 0.5s 1.4s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 0.5s 1.4s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
label:nth-child(15) {
  -webkit-animation: fade-in 0.5s 1.5s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 0.5s 1.5s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
label:nth-child(16) {
  -webkit-animation: fade-in 0.5s 1.6s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 0.5s 1.6s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
label:nth-child(17) {
  -webkit-animation: fade-in 0.5s 1.7s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 0.5s 1.7s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
label:nth-child(18) {
  -webkit-animation: fade-in 0.5s 1.8s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 0.5s 1.8s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
label .dealType {
  font-family: "Comfortaa", cursive;
  padding: 2px 10px 2px 10px;
}
label .dealName {
  font-family: "Comfortaa", cursive;
}
label .dealYear {
  -webkit-animation: animate 4s ease-in-out infinite;
          animation: animate 4s ease-in-out infinite;
}

.dealAction {
  z-index: 2000;
}

.type-cv hr {
  position: absolute;
  left: -10px;
  top: 25%;
}

.dealH2 {
  z-index: 1;
  text-align: center;
  height: 100px;
  font-family: "Comfortaa", cursive;
  z-index: 100;
  line-height: 1;
  position: relative;
}

.dealYear {
  display: block;
  padding: 0 20px;
  line-height: 1;
  transform-origin: right;
  -webkit-animation: flipInY 1s ease-in;
          animation: flipInY 1s ease-in;
  color: white;
  transition: all 2s;
  width: 100px;
  background-color: #0ea4e9;
  border-radius: 0 1rem;
}
.dealYear p {
  transition: all 1s ease-in;
  color: white;
}

.dealList div {
  margin: 10px 0px;
  opacity: 0;
}
.dealList div:nth-child(1) {
  -webkit-animation: fade-in 0.0133333333s 0.01s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 0.0133333333s 0.01s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(1) img {
  transition: all 5s;
}
.dealList div:nth-child(1) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(1):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(1):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(2) {
  -webkit-animation: fade-in 0.0266666667s 0.02s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 0.0266666667s 0.02s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(2) img {
  transition: all 5s;
}
.dealList div:nth-child(2) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(2):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(2):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(3) {
  -webkit-animation: fade-in 0.04s 0.03s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 0.04s 0.03s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(3) img {
  transition: all 5s;
}
.dealList div:nth-child(3) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(3):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(3):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(4) {
  -webkit-animation: fade-in 0.0533333333s 0.04s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 0.0533333333s 0.04s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(4) img {
  transition: all 5s;
}
.dealList div:nth-child(4) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(4):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(4):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(5) {
  -webkit-animation: fade-in 0.0666666667s 0.05s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 0.0666666667s 0.05s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(5) img {
  transition: all 5s;
}
.dealList div:nth-child(5) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(5):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(5):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(6) {
  -webkit-animation: fade-in 0.08s 0.06s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 0.08s 0.06s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(6) img {
  transition: all 5s;
}
.dealList div:nth-child(6) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(6):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(6):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(7) {
  -webkit-animation: fade-in 0.0933333333s 0.07s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 0.0933333333s 0.07s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(7) img {
  transition: all 5s;
}
.dealList div:nth-child(7) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(7):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(7):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(8) {
  -webkit-animation: fade-in 0.1066666667s 0.08s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 0.1066666667s 0.08s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(8) img {
  transition: all 5s;
}
.dealList div:nth-child(8) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(8):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(8):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(9) {
  -webkit-animation: fade-in 0.12s 0.09s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 0.12s 0.09s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(9) img {
  transition: all 5s;
}
.dealList div:nth-child(9) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(9):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(9):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(10) {
  -webkit-animation: fade-in 0.1333333333s 0.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 0.1333333333s 0.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(10) img {
  transition: all 5s;
}
.dealList div:nth-child(10) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(10):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(10):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(11) {
  -webkit-animation: fade-in 0.1466666667s 0.11s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 0.1466666667s 0.11s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(11) img {
  transition: all 5s;
}
.dealList div:nth-child(11) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(11):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(11):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(12) {
  -webkit-animation: fade-in 0.16s 0.12s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 0.16s 0.12s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(12) img {
  transition: all 5s;
}
.dealList div:nth-child(12) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(12):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(12):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(13) {
  -webkit-animation: fade-in 0.1733333333s 0.13s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 0.1733333333s 0.13s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(13) img {
  transition: all 5s;
}
.dealList div:nth-child(13) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(13):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(13):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(14) {
  -webkit-animation: fade-in 0.1866666667s 0.14s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 0.1866666667s 0.14s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(14) img {
  transition: all 5s;
}
.dealList div:nth-child(14) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(14):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(14):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(15) {
  -webkit-animation: fade-in 0.2s 0.15s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 0.2s 0.15s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(15) img {
  transition: all 5s;
}
.dealList div:nth-child(15) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(15):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(15):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(16) {
  -webkit-animation: fade-in 0.2133333333s 0.16s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 0.2133333333s 0.16s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(16) img {
  transition: all 5s;
}
.dealList div:nth-child(16) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(16):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(16):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(17) {
  -webkit-animation: fade-in 0.2266666667s 0.17s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 0.2266666667s 0.17s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(17) img {
  transition: all 5s;
}
.dealList div:nth-child(17) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(17):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(17):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(18) {
  -webkit-animation: fade-in 0.24s 0.18s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 0.24s 0.18s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(18) img {
  transition: all 5s;
}
.dealList div:nth-child(18) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(18):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(18):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(19) {
  -webkit-animation: fade-in 0.2533333333s 0.19s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 0.2533333333s 0.19s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(19) img {
  transition: all 5s;
}
.dealList div:nth-child(19) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(19):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(19):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(20) {
  -webkit-animation: fade-in 0.2666666667s 0.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 0.2666666667s 0.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(20) img {
  transition: all 5s;
}
.dealList div:nth-child(20) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(20):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(20):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(21) {
  -webkit-animation: fade-in 0.28s 0.21s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 0.28s 0.21s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(21) img {
  transition: all 5s;
}
.dealList div:nth-child(21) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(21):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(21):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(22) {
  -webkit-animation: fade-in 0.2933333333s 0.22s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 0.2933333333s 0.22s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(22) img {
  transition: all 5s;
}
.dealList div:nth-child(22) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(22):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(22):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(23) {
  -webkit-animation: fade-in 0.3066666667s 0.23s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 0.3066666667s 0.23s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(23) img {
  transition: all 5s;
}
.dealList div:nth-child(23) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(23):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(23):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(24) {
  -webkit-animation: fade-in 0.32s 0.24s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 0.32s 0.24s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(24) img {
  transition: all 5s;
}
.dealList div:nth-child(24) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(24):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(24):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(25) {
  -webkit-animation: fade-in 0.3333333333s 0.25s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 0.3333333333s 0.25s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(25) img {
  transition: all 5s;
}
.dealList div:nth-child(25) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(25):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(25):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(26) {
  -webkit-animation: fade-in 0.3466666667s 0.26s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 0.3466666667s 0.26s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(26) img {
  transition: all 5s;
}
.dealList div:nth-child(26) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(26):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(26):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(27) {
  -webkit-animation: fade-in 0.36s 0.27s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 0.36s 0.27s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(27) img {
  transition: all 5s;
}
.dealList div:nth-child(27) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(27):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(27):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(28) {
  -webkit-animation: fade-in 0.3733333333s 0.28s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 0.3733333333s 0.28s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(28) img {
  transition: all 5s;
}
.dealList div:nth-child(28) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(28):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(28):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(29) {
  -webkit-animation: fade-in 0.3866666667s 0.29s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 0.3866666667s 0.29s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(29) img {
  transition: all 5s;
}
.dealList div:nth-child(29) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(29):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(29):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(30) {
  -webkit-animation: fade-in 0.4s 0.3s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 0.4s 0.3s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(30) img {
  transition: all 5s;
}
.dealList div:nth-child(30) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(30):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(30):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(31) {
  -webkit-animation: fade-in 0.4133333333s 0.31s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 0.4133333333s 0.31s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(31) img {
  transition: all 5s;
}
.dealList div:nth-child(31) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(31):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(31):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(32) {
  -webkit-animation: fade-in 0.4266666667s 0.32s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 0.4266666667s 0.32s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(32) img {
  transition: all 5s;
}
.dealList div:nth-child(32) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(32):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(32):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(33) {
  -webkit-animation: fade-in 0.44s 0.33s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 0.44s 0.33s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(33) img {
  transition: all 5s;
}
.dealList div:nth-child(33) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(33):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(33):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(34) {
  -webkit-animation: fade-in 0.4533333333s 0.34s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 0.4533333333s 0.34s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(34) img {
  transition: all 5s;
}
.dealList div:nth-child(34) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(34):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(34):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(35) {
  -webkit-animation: fade-in 0.4666666667s 0.35s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 0.4666666667s 0.35s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(35) img {
  transition: all 5s;
}
.dealList div:nth-child(35) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(35):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(35):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(36) {
  -webkit-animation: fade-in 0.48s 0.36s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 0.48s 0.36s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(36) img {
  transition: all 5s;
}
.dealList div:nth-child(36) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(36):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(36):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(37) {
  -webkit-animation: fade-in 0.4933333333s 0.37s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 0.4933333333s 0.37s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(37) img {
  transition: all 5s;
}
.dealList div:nth-child(37) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(37):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(37):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(38) {
  -webkit-animation: fade-in 0.5066666667s 0.38s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 0.5066666667s 0.38s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(38) img {
  transition: all 5s;
}
.dealList div:nth-child(38) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(38):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(38):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(39) {
  -webkit-animation: fade-in 0.52s 0.39s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 0.52s 0.39s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(39) img {
  transition: all 5s;
}
.dealList div:nth-child(39) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(39):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(39):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(40) {
  -webkit-animation: fade-in 0.5333333333s 0.4s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 0.5333333333s 0.4s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(40) img {
  transition: all 5s;
}
.dealList div:nth-child(40) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(40):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(40):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(41) {
  -webkit-animation: fade-in 0.5466666667s 0.41s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 0.5466666667s 0.41s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(41) img {
  transition: all 5s;
}
.dealList div:nth-child(41) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(41):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(41):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(42) {
  -webkit-animation: fade-in 0.56s 0.42s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 0.56s 0.42s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(42) img {
  transition: all 5s;
}
.dealList div:nth-child(42) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(42):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(42):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(43) {
  -webkit-animation: fade-in 0.5733333333s 0.43s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 0.5733333333s 0.43s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(43) img {
  transition: all 5s;
}
.dealList div:nth-child(43) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(43):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(43):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(44) {
  -webkit-animation: fade-in 0.5866666667s 0.44s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 0.5866666667s 0.44s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(44) img {
  transition: all 5s;
}
.dealList div:nth-child(44) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(44):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(44):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(45) {
  -webkit-animation: fade-in 0.6s 0.45s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 0.6s 0.45s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(45) img {
  transition: all 5s;
}
.dealList div:nth-child(45) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(45):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(45):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(46) {
  -webkit-animation: fade-in 0.6133333333s 0.46s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 0.6133333333s 0.46s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(46) img {
  transition: all 5s;
}
.dealList div:nth-child(46) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(46):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(46):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(47) {
  -webkit-animation: fade-in 0.6266666667s 0.47s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 0.6266666667s 0.47s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(47) img {
  transition: all 5s;
}
.dealList div:nth-child(47) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(47):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(47):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(48) {
  -webkit-animation: fade-in 0.64s 0.48s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 0.64s 0.48s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(48) img {
  transition: all 5s;
}
.dealList div:nth-child(48) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(48):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(48):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(49) {
  -webkit-animation: fade-in 0.6533333333s 0.49s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 0.6533333333s 0.49s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(49) img {
  transition: all 5s;
}
.dealList div:nth-child(49) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(49):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(49):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(50) {
  -webkit-animation: fade-in 0.6666666667s 0.5s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 0.6666666667s 0.5s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(50) img {
  transition: all 5s;
}
.dealList div:nth-child(50) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(50):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(50):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(51) {
  -webkit-animation: fade-in 0.68s 0.51s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 0.68s 0.51s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(51) img {
  transition: all 5s;
}
.dealList div:nth-child(51) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(51):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(51):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(52) {
  -webkit-animation: fade-in 0.6933333333s 0.52s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 0.6933333333s 0.52s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(52) img {
  transition: all 5s;
}
.dealList div:nth-child(52) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(52):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(52):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(53) {
  -webkit-animation: fade-in 0.7066666667s 0.53s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 0.7066666667s 0.53s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(53) img {
  transition: all 5s;
}
.dealList div:nth-child(53) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(53):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(53):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(54) {
  -webkit-animation: fade-in 0.72s 0.54s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 0.72s 0.54s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(54) img {
  transition: all 5s;
}
.dealList div:nth-child(54) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(54):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(54):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(55) {
  -webkit-animation: fade-in 0.7333333333s 0.55s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 0.7333333333s 0.55s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(55) img {
  transition: all 5s;
}
.dealList div:nth-child(55) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(55):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(55):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(56) {
  -webkit-animation: fade-in 0.7466666667s 0.56s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 0.7466666667s 0.56s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(56) img {
  transition: all 5s;
}
.dealList div:nth-child(56) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(56):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(56):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(57) {
  -webkit-animation: fade-in 0.76s 0.57s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 0.76s 0.57s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(57) img {
  transition: all 5s;
}
.dealList div:nth-child(57) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(57):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(57):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(58) {
  -webkit-animation: fade-in 0.7733333333s 0.58s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 0.7733333333s 0.58s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(58) img {
  transition: all 5s;
}
.dealList div:nth-child(58) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(58):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(58):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(59) {
  -webkit-animation: fade-in 0.7866666667s 0.59s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 0.7866666667s 0.59s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(59) img {
  transition: all 5s;
}
.dealList div:nth-child(59) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(59):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(59):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(60) {
  -webkit-animation: fade-in 0.8s 0.6s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 0.8s 0.6s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(60) img {
  transition: all 5s;
}
.dealList div:nth-child(60) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(60):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(60):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(61) {
  -webkit-animation: fade-in 0.8133333333s 0.61s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 0.8133333333s 0.61s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(61) img {
  transition: all 5s;
}
.dealList div:nth-child(61) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(61):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(61):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(62) {
  -webkit-animation: fade-in 0.8266666667s 0.62s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 0.8266666667s 0.62s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(62) img {
  transition: all 5s;
}
.dealList div:nth-child(62) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(62):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(62):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(63) {
  -webkit-animation: fade-in 0.84s 0.63s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 0.84s 0.63s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(63) img {
  transition: all 5s;
}
.dealList div:nth-child(63) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(63):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(63):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(64) {
  -webkit-animation: fade-in 0.8533333333s 0.64s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 0.8533333333s 0.64s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(64) img {
  transition: all 5s;
}
.dealList div:nth-child(64) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(64):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(64):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(65) {
  -webkit-animation: fade-in 0.8666666667s 0.65s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 0.8666666667s 0.65s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(65) img {
  transition: all 5s;
}
.dealList div:nth-child(65) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(65):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(65):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(66) {
  -webkit-animation: fade-in 0.88s 0.66s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 0.88s 0.66s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(66) img {
  transition: all 5s;
}
.dealList div:nth-child(66) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(66):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(66):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(67) {
  -webkit-animation: fade-in 0.8933333333s 0.67s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 0.8933333333s 0.67s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(67) img {
  transition: all 5s;
}
.dealList div:nth-child(67) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(67):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(67):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(68) {
  -webkit-animation: fade-in 0.9066666667s 0.68s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 0.9066666667s 0.68s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(68) img {
  transition: all 5s;
}
.dealList div:nth-child(68) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(68):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(68):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(69) {
  -webkit-animation: fade-in 0.92s 0.69s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 0.92s 0.69s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(69) img {
  transition: all 5s;
}
.dealList div:nth-child(69) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(69):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(69):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(70) {
  -webkit-animation: fade-in 0.9333333333s 0.7s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 0.9333333333s 0.7s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(70) img {
  transition: all 5s;
}
.dealList div:nth-child(70) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(70):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(70):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(71) {
  -webkit-animation: fade-in 0.9466666667s 0.71s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 0.9466666667s 0.71s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(71) img {
  transition: all 5s;
}
.dealList div:nth-child(71) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(71):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(71):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(72) {
  -webkit-animation: fade-in 0.96s 0.72s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 0.96s 0.72s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(72) img {
  transition: all 5s;
}
.dealList div:nth-child(72) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(72):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(72):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(73) {
  -webkit-animation: fade-in 0.9733333333s 0.73s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 0.9733333333s 0.73s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(73) img {
  transition: all 5s;
}
.dealList div:nth-child(73) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(73):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(73):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(74) {
  -webkit-animation: fade-in 0.9866666667s 0.74s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 0.9866666667s 0.74s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(74) img {
  transition: all 5s;
}
.dealList div:nth-child(74) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(74):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(74):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(75) {
  -webkit-animation: fade-in 1s 0.75s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 1s 0.75s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(75) img {
  transition: all 5s;
}
.dealList div:nth-child(75) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(75):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(75):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(76) {
  -webkit-animation: fade-in 1.0133333333s 0.76s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 1.0133333333s 0.76s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(76) img {
  transition: all 5s;
}
.dealList div:nth-child(76) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(76):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(76):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(77) {
  -webkit-animation: fade-in 1.0266666667s 0.77s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 1.0266666667s 0.77s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(77) img {
  transition: all 5s;
}
.dealList div:nth-child(77) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(77):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(77):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(78) {
  -webkit-animation: fade-in 1.04s 0.78s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 1.04s 0.78s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(78) img {
  transition: all 5s;
}
.dealList div:nth-child(78) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(78):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(78):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(79) {
  -webkit-animation: fade-in 1.0533333333s 0.79s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 1.0533333333s 0.79s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(79) img {
  transition: all 5s;
}
.dealList div:nth-child(79) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(79):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(79):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(80) {
  -webkit-animation: fade-in 1.0666666667s 0.8s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 1.0666666667s 0.8s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(80) img {
  transition: all 5s;
}
.dealList div:nth-child(80) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(80):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(80):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(81) {
  -webkit-animation: fade-in 1.08s 0.81s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 1.08s 0.81s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(81) img {
  transition: all 5s;
}
.dealList div:nth-child(81) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(81):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(81):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(82) {
  -webkit-animation: fade-in 1.0933333333s 0.82s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 1.0933333333s 0.82s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(82) img {
  transition: all 5s;
}
.dealList div:nth-child(82) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(82):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(82):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(83) {
  -webkit-animation: fade-in 1.1066666667s 0.83s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 1.1066666667s 0.83s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(83) img {
  transition: all 5s;
}
.dealList div:nth-child(83) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(83):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(83):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(84) {
  -webkit-animation: fade-in 1.12s 0.84s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 1.12s 0.84s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(84) img {
  transition: all 5s;
}
.dealList div:nth-child(84) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(84):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(84):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(85) {
  -webkit-animation: fade-in 1.1333333333s 0.85s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 1.1333333333s 0.85s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(85) img {
  transition: all 5s;
}
.dealList div:nth-child(85) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(85):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(85):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(86) {
  -webkit-animation: fade-in 1.1466666667s 0.86s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 1.1466666667s 0.86s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(86) img {
  transition: all 5s;
}
.dealList div:nth-child(86) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(86):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(86):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(87) {
  -webkit-animation: fade-in 1.16s 0.87s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 1.16s 0.87s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(87) img {
  transition: all 5s;
}
.dealList div:nth-child(87) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(87):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(87):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(88) {
  -webkit-animation: fade-in 1.1733333333s 0.88s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 1.1733333333s 0.88s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(88) img {
  transition: all 5s;
}
.dealList div:nth-child(88) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(88):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(88):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(89) {
  -webkit-animation: fade-in 1.1866666667s 0.89s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 1.1866666667s 0.89s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(89) img {
  transition: all 5s;
}
.dealList div:nth-child(89) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(89):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(89):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(90) {
  -webkit-animation: fade-in 1.2s 0.9s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 1.2s 0.9s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(90) img {
  transition: all 5s;
}
.dealList div:nth-child(90) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(90):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(90):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(91) {
  -webkit-animation: fade-in 1.2133333333s 0.91s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 1.2133333333s 0.91s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(91) img {
  transition: all 5s;
}
.dealList div:nth-child(91) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(91):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(91):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(92) {
  -webkit-animation: fade-in 1.2266666667s 0.92s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 1.2266666667s 0.92s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(92) img {
  transition: all 5s;
}
.dealList div:nth-child(92) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(92):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(92):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(93) {
  -webkit-animation: fade-in 1.24s 0.93s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 1.24s 0.93s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(93) img {
  transition: all 5s;
}
.dealList div:nth-child(93) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(93):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(93):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(94) {
  -webkit-animation: fade-in 1.2533333333s 0.94s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 1.2533333333s 0.94s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(94) img {
  transition: all 5s;
}
.dealList div:nth-child(94) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(94):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(94):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(95) {
  -webkit-animation: fade-in 1.2666666667s 0.95s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 1.2666666667s 0.95s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(95) img {
  transition: all 5s;
}
.dealList div:nth-child(95) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(95):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(95):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(96) {
  -webkit-animation: fade-in 1.28s 0.96s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 1.28s 0.96s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(96) img {
  transition: all 5s;
}
.dealList div:nth-child(96) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(96):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(96):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(97) {
  -webkit-animation: fade-in 1.2933333333s 0.97s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 1.2933333333s 0.97s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(97) img {
  transition: all 5s;
}
.dealList div:nth-child(97) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(97):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(97):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(98) {
  -webkit-animation: fade-in 1.3066666667s 0.98s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 1.3066666667s 0.98s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(98) img {
  transition: all 5s;
}
.dealList div:nth-child(98) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(98):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(98):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(99) {
  -webkit-animation: fade-in 1.32s 0.99s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 1.32s 0.99s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(99) img {
  transition: all 5s;
}
.dealList div:nth-child(99) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(99):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(99):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}
.dealList div:nth-child(100) {
  -webkit-animation: fade-in 1.3333333333s 1s forwards cubic-bezier(0.11, 0, 0.5, 0);
          animation: fade-in 1.3333333333s 1s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.dealList div:nth-child(100) img {
  transition: all 5s;
}
.dealList div:nth-child(100) img:hover {
  rotate: -2deg;
  transition: 3s;
  -webkit-animation: kenburns-top-left 5s ease-out both;
          animation: kenburns-top-left 5s ease-out both;
}
.dealList div:nth-child(100):hover ~ .dealCard {
  transition: all 5s;
}
.dealList div:nth-child(100):hover ~ .dealCard img {
  transition: all 3s;
  rotate: -2deg;
}

.dealCard:hover > .dealYear {
  display: block;
  width: 40px;
  transform-origin: right;
  -webkit-animation: animate 2s infinite;
          animation: animate 2s infinite;
  background-color: rgba(14, 164, 233, 0.3019607843);
  z-index: 1000;
}
.dealCard:hover > .dealYear p {
  color: rgba(14, 164, 233, 0.2705882353);
  transition: all 0.2s;
}

.dealContent::before {
  display: block;
  content: "";
  position: relative;
  margin-bottom: 10px;
  width: 100%;
  height: 10px;
  -webkit-animation: animate 8s infinite;
          animation: animate 8s infinite;
  background-color: rgba(14, 164, 233, 0.3019607843);
}

.profil {
  position: relative;
  display: flex;
  border-radius: 2rem;
}
.profil .descCv {
  position: absolute;
  display: flex;
  flex-direction: column;
  padding: 2rem 0;
  bottom: 0;
  right: 0;
  width: 100%;
  font-size: 36px;
  line-height: 0.5;
  text-align: right;
  font-family: "Comfortaa", cursive;
}
.profil:hover .img {
  background-position: bottom;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.profil .img {
  width: 400px;
  height: 400px;
  border-radius: 2rem;
  margin: 2rem 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("./images/Ben/profil.jpg");
  filter: grayscale(100%) contrast(120%);
  box-shadow: 10px 15px 25px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.profil .img:hover img {
  box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.1);
}

.profil:hover ~ article .h3Cv {
  --tw-scale-x: .9;
  --tw-scale-y: .9;
  transform: rotate3d(1, -1, -1, -5deg) translateX(-10px) translateY(10px);
  transition: all 1s ease-in-out;
}

.headerCv {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-left: 2rem;
}
.headerCv .titleC {
  font-size: 150px;
  position: relative;
  font-family: "Comfortaa", cursive;
  z-index: 100;
  top: 75px;
  line-height: 1;
  display: flex;
  justify-content: flex-start;
  width: 100%;
}
.headerCv .titleC small {
  color: rgba(14, 164, 233, 0.8588235294);
  position: absolute;
  transform: translate(0%, -50%);
  text-shadow: 0px 0px 1px rgba(255, 255, 255, 0.5);
  transition: all 1500ms;
}
.headerCv .titleC small:nth-child(1) {
  color: rgba(14, 164, 233, 0.25);
  transition: all 1500ms;
}
.headerCv .titleC small:nth-child(2) {
  transition: all 1500ms;
  -webkit-animation: animate 8s ease-in-out infinite;
          animation: animate 8s ease-in-out infinite;
}
.headerCv .titleV {
  display: flex;
  font-size: 100px;
  position: relative;
  font-family: "Comfortaa", cursive;
  top: 150px;
  padding-right: 75px;
  color: white;
  box-shadow: 10px;
  line-height: 1;
}
.headerCv .titleV b {
  color: #075985;
  position: absolute;
  transform: translate(0%, -25%);
  transition: all 500ms;
  height: 100px;
}
.headerCv .titleV b:nth-child(1) {
  color: rgba(14, 164, 233, 0.533);
  -webkit-text-stroke: 0px;
  transition: all 500ms;
}
.headerCv .titleV b:nth-child(2) {
  color: #075985;
  -webkit-animation: animate 20s ease-in-out infinite;
          animation: animate 20s ease-in-out infinite;
  transition: all 500ms;
}

.anim-dealTitle .dealH2 b {
  color: #075985;
  position: absolute;
  transform: translate(0%, 0%);
  transition: all 500ms;
}
.anim-dealTitle .dealH2 b:nth-child(1) {
  color: rgba(14, 164, 233, 0.533);
  -webkit-text-stroke: 0px;
  transition: all 500ms;
}
.anim-dealTitle .dealH2 b:nth-child(2) {
  color: #075985;
  -webkit-animation: animate 3s ease-in-out infinite;
          animation: animate 3s ease-in-out infinite;
  transition: all 500ms;
}

.btn {
  font-family: "Comfortaa", cursive;
  font-family: "Righteous", cursive;
}

.btn-link {
  color: #fff;
  background-color: #075985;
  z-index: 1000;
  transition: all 0.5s ease-in-out;
  box-shadow: 0px 0.2em 0.5em rgba(14, 165, 233, 0.16);
}

.btn-link:hover {
  color: #075985;
  background-color: #fff;
  z-index: 1000;
  transition: all 0.5s ease-in-out;
  box-shadow: none;
  border-right: solid 5px #075985;
}
.btn-link:hover span {
  transform: rotateY(-30deg);
}

.btn-default {
  color: #075985;
  background-color: #fff;
  z-index: 1000;
  font-weight: 200;
  transition: all 0.1s ease-in-out;
}

.btn-default:hover {
  background-color: #075985;
  color: #fff;
  z-index: 1000;
  transition: all 0.25s ease-in-out;
  box-shadow: none;
  border-right: solid 5px white;
  box-shadow: 0.5em 0em 0.5em rgba(14, 165, 233, 0.16);
}

@-webkit-keyframes animate {
  from {
    -webkit-clip-path: polygon(0 44%, 7% 58%, 14% 67%, 20% 66%, 30% 68%, 48% 58%, 51% 60%, 55% 56%, 65% 58%, 75% 56%, 83% 49%, 92% 40%, 100% 28%, 100% 100%, 0% 100%);
            clip-path: polygon(0 44%, 7% 58%, 14% 67%, 20% 66%, 30% 68%, 48% 58%, 51% 60%, 55% 56%, 65% 58%, 75% 56%, 83% 49%, 92% 40%, 100% 28%, 100% 100%, 0% 100%);
  }
  20% {
    -webkit-clip-path: polygon(0% 33%, 8% 26%, 11% 31%, 13% 51%, 19% 58%, 27% 58%, 31% 66%, 38% 70%, 49% 64%, 52% 66%, 66% 56%, 81% 60%, 100% 60%, 100% 100%, 0% 100%);
            clip-path: polygon(0% 33%, 8% 26%, 11% 31%, 13% 51%, 19% 58%, 27% 58%, 31% 66%, 38% 70%, 49% 64%, 52% 66%, 66% 56%, 81% 60%, 100% 60%, 100% 100%, 0% 100%);
  }
  25% {
    -webkit-clip-path: polygon(0% 33%, 8% 26%, 11% 31%, 13% 51%, 19% 58%, 27% 58%, 31% 66%, 38% 70%, 49% 64%, 52% 66%, 66% 56%, 81% 60%, 100% 60%, 100% 100%, 0% 100%);
            clip-path: polygon(0% 33%, 8% 26%, 11% 31%, 13% 51%, 19% 58%, 27% 58%, 31% 66%, 38% 70%, 49% 64%, 52% 66%, 66% 56%, 81% 60%, 100% 60%, 100% 100%, 0% 100%);
  }
  50% {
    -webkit-clip-path: polygon(0 68%, 9% 65%, 15% 60%, 21% 53%, 31% 37%, 39% 26%, 47% 27%, 52% 55%, 57% 57%, 69% 69%, 81% 58%, 90% 60%, 100% 63%, 100% 100%, 0% 100%);
            clip-path: polygon(0 68%, 9% 65%, 15% 60%, 21% 53%, 31% 37%, 39% 26%, 47% 27%, 52% 55%, 57% 57%, 69% 69%, 81% 58%, 90% 60%, 100% 63%, 100% 100%, 0% 100%);
  }
  75% {
    -webkit-clip-path: polygon(0 59%, 9% 70%, 23% 72%, 38% 64%, 54% 52%, 66% 38%, 76% 21%, 80% 27%, 83% 37%, 80% 53%, 84% 60%, 91% 54%, 100% 63%, 100% 100%, 0% 100%);
            clip-path: polygon(0 59%, 9% 70%, 23% 72%, 38% 64%, 54% 52%, 66% 38%, 76% 21%, 80% 27%, 83% 37%, 80% 53%, 84% 60%, 91% 54%, 100% 63%, 100% 100%, 0% 100%);
  }
  to {
    -webkit-clip-path: polygon(0 44%, 7% 58%, 14% 67%, 20% 66%, 30% 68%, 48% 58%, 51% 60%, 55% 56%, 65% 58%, 75% 56%, 83% 49%, 92% 40%, 100% 28%, 100% 100%, 0% 100%);
            clip-path: polygon(0 44%, 7% 58%, 14% 67%, 20% 66%, 30% 68%, 48% 58%, 51% 60%, 55% 56%, 65% 58%, 75% 56%, 83% 49%, 92% 40%, 100% 28%, 100% 100%, 0% 100%);
  }
}

@keyframes animate {
  from {
    -webkit-clip-path: polygon(0 44%, 7% 58%, 14% 67%, 20% 66%, 30% 68%, 48% 58%, 51% 60%, 55% 56%, 65% 58%, 75% 56%, 83% 49%, 92% 40%, 100% 28%, 100% 100%, 0% 100%);
            clip-path: polygon(0 44%, 7% 58%, 14% 67%, 20% 66%, 30% 68%, 48% 58%, 51% 60%, 55% 56%, 65% 58%, 75% 56%, 83% 49%, 92% 40%, 100% 28%, 100% 100%, 0% 100%);
  }
  20% {
    -webkit-clip-path: polygon(0% 33%, 8% 26%, 11% 31%, 13% 51%, 19% 58%, 27% 58%, 31% 66%, 38% 70%, 49% 64%, 52% 66%, 66% 56%, 81% 60%, 100% 60%, 100% 100%, 0% 100%);
            clip-path: polygon(0% 33%, 8% 26%, 11% 31%, 13% 51%, 19% 58%, 27% 58%, 31% 66%, 38% 70%, 49% 64%, 52% 66%, 66% 56%, 81% 60%, 100% 60%, 100% 100%, 0% 100%);
  }
  25% {
    -webkit-clip-path: polygon(0% 33%, 8% 26%, 11% 31%, 13% 51%, 19% 58%, 27% 58%, 31% 66%, 38% 70%, 49% 64%, 52% 66%, 66% 56%, 81% 60%, 100% 60%, 100% 100%, 0% 100%);
            clip-path: polygon(0% 33%, 8% 26%, 11% 31%, 13% 51%, 19% 58%, 27% 58%, 31% 66%, 38% 70%, 49% 64%, 52% 66%, 66% 56%, 81% 60%, 100% 60%, 100% 100%, 0% 100%);
  }
  50% {
    -webkit-clip-path: polygon(0 68%, 9% 65%, 15% 60%, 21% 53%, 31% 37%, 39% 26%, 47% 27%, 52% 55%, 57% 57%, 69% 69%, 81% 58%, 90% 60%, 100% 63%, 100% 100%, 0% 100%);
            clip-path: polygon(0 68%, 9% 65%, 15% 60%, 21% 53%, 31% 37%, 39% 26%, 47% 27%, 52% 55%, 57% 57%, 69% 69%, 81% 58%, 90% 60%, 100% 63%, 100% 100%, 0% 100%);
  }
  75% {
    -webkit-clip-path: polygon(0 59%, 9% 70%, 23% 72%, 38% 64%, 54% 52%, 66% 38%, 76% 21%, 80% 27%, 83% 37%, 80% 53%, 84% 60%, 91% 54%, 100% 63%, 100% 100%, 0% 100%);
            clip-path: polygon(0 59%, 9% 70%, 23% 72%, 38% 64%, 54% 52%, 66% 38%, 76% 21%, 80% 27%, 83% 37%, 80% 53%, 84% 60%, 91% 54%, 100% 63%, 100% 100%, 0% 100%);
  }
  to {
    -webkit-clip-path: polygon(0 44%, 7% 58%, 14% 67%, 20% 66%, 30% 68%, 48% 58%, 51% 60%, 55% 56%, 65% 58%, 75% 56%, 83% 49%, 92% 40%, 100% 28%, 100% 100%, 0% 100%);
            clip-path: polygon(0 44%, 7% 58%, 14% 67%, 20% 66%, 30% 68%, 48% 58%, 51% 60%, 55% 56%, 65% 58%, 75% 56%, 83% 49%, 92% 40%, 100% 28%, 100% 100%, 0% 100%);
  }
}
@-webkit-keyframes fade-in {
  to {
    opacity: 1;
    filter: blur(0);
  }
}
@keyframes fade-in {
  to {
    opacity: 1;
    filter: blur(0);
  }
}
@-webkit-keyframes scale {
  to {
    transform: scale(1.5);
  }
}
@keyframes scale {
  to {
    transform: scale(1.5);
  }
}/*# sourceMappingURL=style.css.map */