@import url('https://fonts.googleapis.com/css2?family=Annie+Use+Your+Telescope&display=swap')
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&family=Righteous&display=swap')

$height: calc(100vh - 120px)
$heigh-md: calc(100vh - 40px)


body, html
  background-color: #f2f2f2
  overflow: $height
  font-family: 'Annie Use Your Telescope', cursive
  overflow: hidden 
main
  margin-top: 120px
  overflow: auto
  height: $height
  background-color: #f2f2f2
  z-index: 10
  @media (min-width: 768px) 
    margin-top: 80px
  &::-webkit-scrollbar 
    width: 20px
    &-track
      background-color: white
      box-shadow: inset 0 0 1em #0c4a6e69
      border-radius: 50px
      height: 10px
    &-thumb 
      border-radius: 100px
      border: 5px solid transparent
      background-clip: content-box
      background-color: white
      height: 50px

  &:hover::-webkit-scrollbar-track
    background-color: white
    box-shadow: inset 0 0 1em #0c4a6eee
    border-radius: 100px
    margin-right: 10px
    transition: all 8s ease-in


main::-webkit-scrollbar-thumb 
  border-radius: 100px
  border: 5px solid transparent
  background-clip: content-box
  background-color: #0ea4e9
  height: 50px

section  
  height: $height
  font-family: 'Comfortaa', cursive
  
header
  top: 0
  display: block
  z-index: 1000

article
  // padding-bottom: 2rem
 
nav
  &.nav
    border-radius: 100% 
    &::before
      display: flex
      height: 50px
      width: 50px
      content: ""
      background-color: #ffffff80
      animation: animate 3s infinite

footer
  height: 40px
  width: 100%
  position: fixed
  z-index: 500
  bottom: 0

// h1
//   animation: text-flicker-in-glow 4s linear alternate-reverse backwards
//   &:hover
//     animation: text-flicker-in-glow 2s linear infinite alternate-reverse backwards
//   &:target
//     animation: text-flicker-in-glow

article .h3Cv
  color: #0ea5e9
  font-family: 'Annie Use Your Telescope', cursive
  font-size: 40px
  transition: all .75s ease-in-out

article::-webkit-scrollbar 
  width: 20px

article::-webkit-scrollbar-track
  background-color: white
  box-shadow: inset 0 0 1em #0c4a6e
  border-radius: 50px
  height: 10px

article:hover::-webkit-scrollbar-track
  background-color: white
  box-shadow: inset 0 0 1em #0ea5e9
  border-radius: 100px
  margin-right: 10px
  transition: all 8s ease-in


article::-webkit-scrollbar-thumb 
  border-radius: 100px
  border: 5px solid transparent
  background-clip: content-box
  background-color: white
  height: 50px

article.computerSkills label
  width: 100%
  font-size: 20px
  font-weight: 400
  font-family: 'Righteous', cursive
  color:#0c4a6e

label
  margin-bottom: 10px
  background-color: transparent
  display: block
  opacity: 0
  @for $i from 1 through 18 
    $time: ($i/10)+s
    &:nth-child(#{$i}) 
      animation: fade-in .5s $time forwards cubic-bezier(0.11, 0, 0.5, 0)
  
  .dealType
    font-family: 'Comfortaa', cursive   
    padding: 2px 10px 2px 10px
  
  .dealName
    font-family: 'Comfortaa', cursive

  .dealYear
    animation: animate 4s ease-in-out infinite

.dealAction
  z-index: 2000



.type-cv hr
  position: absolute
  left: -10px
  top: 25%

.dealH2
  z-index: 1
  text-align: center
  height: 100px
  font-family: 'Comfortaa', cursive
  z-index: 100
  line-height: 1
  position: relative


.dealYear
  display: block
  padding: 0 20px
  line-height: 1
  transform-origin: right
  animation: flipInY 1s ease-in
  color: white
  transition: all 2s
  width: 100px
  background-color: #0ea4e9
  border-radius:0 1rem 
  p
    transition: all 1s ease-in
    color: white

.dealList div
  margin: 10px 0px
  opacity: 0
  @for $b from 1 through 100
    $time: ($b/100)+s
    $time2: ($b/75)+s
    $time3: ($b/75)+s
    &:nth-child(#{$b}) 
      animation: fade-in $time2 $time forwards cubic-bezier(0.11, 0, 0.5, 0)  
      img
        transition: all 5s
      img:hover
        rotate: -2deg
        transition: 3s
        animation: kenburns-top-left 5s ease-out both
      &:hover ~ .dealCard
        transition: all 5s
        img
          transition: all 3s
          rotate: -2deg
        

.dealCard:hover > .dealYear
  display: block
  width: 40px
  transform-origin: right
  animation: animate 2s infinite
  background-color: #0ea4e94d
  z-index: 1000
  p
    color: #0ea4e945
    transition: all .2s

.dealContent::before
  display: block
  content: ""
  position: relative
  margin-bottom: 10px
  width: 100%
  height: 10px
  animation: animate 8s infinite
  background-color: #0ea4e94d


.profil
  position: relative
  display: flex
  border-radius: 2rem 
  .descCv
    position: absolute
    display: flex
    flex-direction: column
    padding:  2rem 0
    bottom: 0
    right: 0
    width: 100% 
    font-size: 36px
    line-height: .5
    text-align: right
    font-family: 'Comfortaa', cursive
  &:hover
    .descCv 
    .img
      background-position: bottom
      transition: all .25s cubic-bezier(0.645, 0.045, 0.355, 1)

  .img
    width: 400px
    height: 400px
    border-radius: 2rem 
    margin:  2rem 0
    background-size: cover 
    background-repeat: no-repeat  
    background-image: url("./images/Ben/profil.jpg")
    filter: grayscale(100%) contrast(120%)
    box-shadow: 10px 15px 25px 0 rgba(0,0,0,.2)
    display: flex
    transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1)
    &:hover img
      box-shadow: 1px 1px 10px 0 rgba(0,0,0,.1)
      
.profil:hover ~ article .h3Cv 
  --tw-scale-x: .9
  --tw-scale-y: .9
  transform: rotate3d(1, -1, -1, -5deg) translateX(-10px) translateY(10px)
  transition: all 1s ease-in-out


.headerCv
  position: relative
  display: flex
  flex-direction: column
  padding-left: 2rem
  .titleC
    font-size: 150px
    position: relative
    font-family: 'Comfortaa', cursive
    z-index: 100
    top: 75px
    line-height: 1
    display: flex
    justify-content: flex-start
    width: 100% 
    small
      color: #0ea4e9db
      position: absolute
      transform: translate(0%, -50%)
      text-shadow: 0px 0px 1px rgba(255,255,255,.5)
      transition: all 1500ms
      &:nth-child(1) 
        color: rgba(14, 164, 233, 0.25)
        transition: all 1500ms
      &:nth-child(2) 
        transition: all 1500ms
        animation: animate 8s ease-in-out infinite

  .titleV 
    display: flex
    font-size: 100px
    position: relative
    font-family: 'Comfortaa', cursive
    top: 150px
    padding-right: 75px
    color: white
    box-shadow: 10px 
    line-height: 1
    b 
      color: #075985
      position: absolute
      transform: translate(0%, -25%)
      transition: all 500ms
      height: 100px
      &:nth-child(1) 
        color: rgba(14, 164, 233, 0.533)
        -webkit-text-stroke: 0px
        transition: all 500ms
      &:nth-child(2) 
        color: #075985
        animation: animate 20s ease-in-out infinite
        transition: all 500ms

.anim-dealTitle 
  .dealH2 
    b 
      color: #075985
      position: absolute
      transform: translate(0%, 0%)
      transition: all 500ms
    b:nth-child(1) 
      color: rgba(14, 164, 233, 0.533)
      -webkit-text-stroke: 0px
      transition: all 500ms
    b:nth-child(2) 
      color: #075985
      animation: animate 3s ease-in-out infinite
      transition: all 500ms

.btn
  font-family: 'Comfortaa', cursive
  font-family: 'Righteous', cursive
  

.btn-link
  color:#fff
  background-color: #075985
  z-index: 1000
  transition: all .5s ease-in-out
  box-shadow:  0px .2em .5em rgba(14, 165, 233, .16)

.btn-link:hover
  color:#075985
  background-color: #fff
  z-index: 1000
  transition: all .5s ease-in-out
  box-shadow: none
  border-right: solid 5px #075985
  span
    transform: rotateY(-30deg) 

.btn-default
  color: #075985
  background-color: #fff
  z-index: 1000
  font-weight: 200
  transition: all .1s ease-in-out
  
.btn-default:hover
  background-color: #075985
  color:#fff 
  z-index: 1000
  transition: all .25s ease-in-out
  box-shadow: none
  border-right: solid 5px white
  box-shadow:  .5em .0em .5em rgba(14, 165, 233, .16)


@keyframes animate
 from
  clip-path: polygon(0 44%, 7% 58%, 14% 67%, 20% 66%, 30% 68%, 48% 58%, 51% 60%, 55% 56%, 65% 58%, 75% 56%, 83% 49%, 92% 40%, 100% 28%, 100% 100%, 0% 100%)
 
 20%
  clip-path: polygon(0% 33%, 8% 26%, 11% 31%, 13% 51%, 19% 58%, 27% 58%, 31% 66%, 38% 70%, 49% 64%, 52% 66%, 66% 56%, 81% 60%, 100% 60%, 100% 100%, 0% 100%)

 25%
  clip-path: polygon(0% 33%, 8% 26%, 11% 31%, 13% 51%, 19% 58%, 27% 58%, 31% 66%, 38% 70%, 49% 64%, 52% 66%, 66% 56%, 81% 60%, 100% 60%, 100% 100%, 0% 100%)

 50%
  clip-path: polygon(0 68%, 9% 65%, 15% 60%, 21% 53%, 31% 37%, 39% 26%, 47% 27%, 52% 55%, 57% 57%, 69% 69%, 81% 58%, 90% 60%, 100% 63%, 100% 100%, 0% 100%)

 75%
  clip-path: polygon(0 59%, 9% 70%, 23% 72%, 38% 64%, 54% 52%, 66% 38%, 76% 21%, 80% 27%, 83% 37%, 80% 53%, 84% 60%, 91% 54%, 100% 63%, 100% 100%, 0% 100%)
  
 to
  clip-path: polygon(0 44%, 7% 58%, 14% 67%, 20% 66%, 30% 68%, 48% 58%, 51% 60%, 55% 56%, 65% 58%, 75% 56%, 83% 49%, 92% 40%, 100% 28%, 100% 100%, 0% 100%)


@keyframes fade-in
  to
    opacity: 1
    filter: blur(0)

@keyframes scale
  to
    transform: scale(1.5)



 